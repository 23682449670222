import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import Parallax from "components/Parallax/Parallax";

// Sections for this page
import SectionSolutions from "./SolutionsPage/Sections/SectionSolutions";

const useStyles = makeStyles(aboutUsStyle);

// markup
const SolutionsPage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax
        image={require("assets/img/creativeopswebsitebanner.jpg")}
        filter="dark"
        small
      ></Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionSolutions />
        </div>
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default SolutionsPage;
